import React, { useState } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import DesignTokens from "./designTokens"
import { motion, AnimatePresence } from "framer-motion"
import { X, Menu } from "react-feather"
import logo from "../images/formenti-logo.svg"
import PrimaryMenu from "./primaryMenu"

const Nav = () => {
  const [showMenu, setShowMenu] = useState(false)
  return (
    <nav>
      <StyledMenu>
        <div>
          <Link to="/">
            <img src={logo} alt="Carpenteria Formenti Logo" />
          </Link>
        </div>
        <div
          role="button"
          tabIndex={0}
          aria-label="Menu"
          onClick={() => setShowMenu(!showMenu)}
          onKeyDown={() => setShowMenu(!showMenu)}
        >
          <Menu color={DesignTokens.colors.white} size={32} />
        </div>
      </StyledMenu>
      <AnimatePresence>
        {showMenu && (
          <StyledOverlay
            initial={{ opacity: 0, scale: 1.2 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.15 }}
            exit={{ opacity: 0 }}
          >
            <header>
              <X size={32} onClick={() => setShowMenu(false)} />
            </header>
            <PrimaryMenu />
          </StyledOverlay>
        )}
      </AnimatePresence>
    </nav>
  )
}

const StyledMenu = styled.div`
  padding: 0;
  height: 70px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: ${DesignTokens.colors.primary[500]};
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 0 ${DesignTokens.spacing[5]};
  }
`

const StyledOverlay = styled(motion.div)`
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  color: ${DesignTokens.colors.white};
  background: ${DesignTokens.colors.tertiary[500]};
  z-index: 10;
  header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 70px;
    text-align: right;
    padding: 0 1.2rem;
  }
  ul {
    display: flex;
    flex-direction: column;
    li {
      font-size: ${DesignTokens.fontSizes[7]};
      padding: ${DesignTokens.spacing[3]};
      text-align: center;
      margin: 0;
      a {
        font-family: "Yantramanav";
        color: ${DesignTokens.colors.white};
        text-decoration: none;
      }
    }
  }
`

export default Nav
