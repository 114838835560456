import React from "react"
import { Link } from "gatsby"
import { ItemsList } from "./utils"

const PrimaryMenu = () => (
  <ItemsList>
    <li>
      <Link to="/carpenteria-formenti/">Carpenteria</Link>
    </li>
    <li>
      <Link to="/processo-e-lavorazioni/">Lavorazioni</Link>
    </li>
    <li>
      <Link to="/parco-macchine/">Macchine</Link>
    </li>
    <li>
      <Link to="/soluzioni-applicative/">Soluzioni</Link>
    </li>
    <li>
      <Link to="/contatti/">Contatti</Link>
    </li>
    <li>
      <Link to="/lavora-con-noi/">Lavora con noi</Link>
    </li>
  </ItemsList>
)

export default PrimaryMenu
