const DesignTokens = {
  colors: {
    white: "#fff",
    black: "#545454",
    primary: {
      500: "#009FE3",
    },
    secondary: {
      500: "#ffffff",
    },
    tertiary: {
      100: "#e3e3e3",
      200: "#bdbdbd",
      300: "#969696",
      400: "#707070",
      500: "#4A4A4A",
    },
  },
  borderRadius: ["0.25rem"],
  spacing: [
    "0rem",
    "0.25rem",
    "0.5rem",
    "0.75rem",
    "1rem",
    "1.25rem",
    "1.5rem",
    "2rem",
    "2.5rem",
    "3rem",
  ],
  fontSizes: [
    "0rem",
    "0.5rem",
    "0.75rem",
    "0.875rem",
    "1rem",
    "1.25rem",
    "1.75rem",
    "2rem",
    "2.5rem",
    "3rem",
  ],
  elevation: {
    raised:
      "rgba(46, 41, 51, 0.08) 0px 1px 2px, rgba(71, 63, 79, 0.08) 0px 2px 4px;",
    floating:
      "rgba(46, 41, 51, 0.08) 0px 2px 4px, rgba(71, 63, 79, 0.16) 0px 4px 8px;",
    overlay:
      "rgba(46, 41, 51, 0.08) 0px 4px 8px, rgba(71, 63, 79, 0.16) 0px 8px 16px;",
    dialog:
      "rgba(46, 41, 51, 0.08) 0px 4px 16px, rgba(71, 63, 79, 0.16) 0px 8px 24px;",
  },
}

export default DesignTokens
