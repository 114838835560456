import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import DesignTokens from "./designTokens"
import logo from "../images/formenti-logo.svg"
import PrimaryMenu from "./primaryMenu"

const LargeNav = () => {
  return (
    <nav>
      <StyledMenu>
        <div>
          <Link to="/">
            <img src={logo} alt="Carpenteria Formenti Logo" />
          </Link>
        </div>
        <PrimaryMenu />
      </StyledMenu>
    </nav>
  )
}

const StyledMenu = styled.div`
  padding: 0;
  display: flex;
  flex-direction: row;
  background: ${DesignTokens.colors.secondary[500]};
  div {
    background: ${DesignTokens.colors.primary[500]};
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 275px;
  }
  ul {
    height: 70px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: stretch;

    li {
      margin: 0;
      text-align: center;
      width: calc(100% / 6);
      border-right: 1px solid ${DesignTokens.colors.tertiary[100]};

      &:last-child {
        border-right: 0;
      }
      a {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        font-weight: 900;
        color: ${DesignTokens.colors.tertiary[500]};
        text-decoration: none;
        &:active,
        &:hover {
          color: ${DesignTokens.colors.primary[500]};
        }
      }
    }
  }
`

export default LargeNav
