import styled from "styled-components"

const ItemsList = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
`

const IsDesktop = styled.div`
  display: none;
  @media screen and (min-width: 769px) {
    display: block;
  }
`

const IsMobile = styled.div`
  display: block;
  @media screen and (min-width: 769px) {
    display: none;
  }
`


export { ItemsList, IsDesktop, IsMobile }
