import React from "react"
import Nav from "../components/nav"
import Helmet from "react-helmet"
import LargeNav from "./largeNav"
import { IsDesktop, IsMobile } from "./utils"

const Layout = ({ children }) => {
  return (
    <div>
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;900&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <IsDesktop>
        <LargeNav />
      </IsDesktop>
      <IsMobile>
        <Nav />
      </IsMobile>
      {children}
    </div>
  )
}

export default Layout
